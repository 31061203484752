"use client";

import { BannerSection } from "./components/sections/BannerSection";
import { PrimaryButton } from "./components/buttons/PrimaryButton";
import ReservationButton from "./components/buttons/ReservationButton";
import { BreadcrumbHomepage } from "./components/breadcrumbs/BreadcrumbHomepage";

export default function NotFound() {
  return (
    <main className="">
      <BannerSection title="Page non trouvée" />
      <BreadcrumbHomepage />

      <section className="flex min-h-[55dvh] flex-col items-center px-4 py-16 text-black ph:px-24 md:px-48 lg:px-72 xl:px-96">
        <div className="mb-12 flex flex-col items-center">
          <h2 className="text-center text-3xl font-light tracking-wide">
            La page que vous recherchez n&apos;existe pas
          </h2>

          <p className="mt-4 max-w-2xl text-center text-lg font-light text-gray-600">
            Nous sommes désolés, mais la page que vous avez demandée n&apos;a
            pas été trouvée. Vous pouvez retourner à l&apos;accueil ou réserver
            directement.
          </p>
        </div>

        <div className="mt-8 flex gap-4 sm:flex-row">
          <PrimaryButton
            text="Retour à l'accueil"
            navigateTo="/"
            variant="black"
          />
          <ReservationButton text="Réserver" variant="primary" />
        </div>
      </section>
    </main>
  );
}
