"use client";

import Link from "next/link";
import { MdiChevronLeft } from "@/assets/icons/mdi-chevron-left";
import { cn } from "@/utils/cn";
import { useI18n } from "@/i18n/client";
import { getLocalizedHref } from "@/utils/localization";

export const BreadcrumbHomepage = ({ className }: { className?: string }) => {
  const { t, locale } = useI18n();

  return (
    <section
      className={cn(
        "flex w-full flex-row justify-start px-4 py-6",
        "ph:px-24 md:px-48 lg:px-72 xl:px-96",
        "bg-primary",
        className,
      )}
    >
      <div>
        <Link
          href={getLocalizedHref("/", locale)}
          className="group font-cormorant font-light text-black"
        >
          <div className="flex items-center gap-1 transition-all duration-300 hover:translate-x-[-2px]">
            <MdiChevronLeft className="h-5 w-5 text-black duration-300 group-hover:text-secondary" />
            <span className="text-xl duration-300 group-hover:text-secondary">
              {t("breadcrumb.backToHome")}
            </span>
          </div>
        </Link>
      </div>
    </section>
  );
};
