"use client";

import React from "react";
import { useI18n } from "@/i18n/client";
import Link from "next/link";

interface ReservationButtonProps {
  text?: string;
  variant?: "primary" | "secondary" | "white";
  className?: string;
  fullWidth?: boolean;
  roomNumber?: string;
  translationKey?: string;
}

export const ReservationButton = ({
  text,
  variant = "primary",
  className = "",
  fullWidth = false,
  roomNumber,
  translationKey = "cta.book",
}: ReservationButtonProps) => {
  const { t } = useI18n();
  const buttonText = text || t(translationKey as any);

  const baseClasses =
    "inline-block rounded-full px-6 py-3 text-center font-light transition-all duration-300";

  const variantClasses = {
    primary: "bg-[#4A7C59] text-white hover:bg-[#3a6347]",
    secondary:
      "border border-[#4A7C59] text-[#4A7C59] hover:bg-[#4A7C59] hover:text-white",
    white: "bg-white text-[#4A7C59] hover:bg-gray-100",
  };

  const widthClass = fullWidth ? "w-full" : "";

  return (
    <Link
      href="https://www.gites-de-france.com/fr/normandie/seine-maritime/les-chambres-dhotes-danne-76g24024"
      target="_blank"
      rel="noopener noreferrer"
      className={`${baseClasses} ${variantClasses[variant]} ${widthClass} ${className}`}
    >
      {buttonText}
    </Link>
  );
};

export default ReservationButton;
