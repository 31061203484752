interface BannerSectionProps {
  title: string;
}

export const BannerSection = ({ title }: BannerSectionProps) => {
  return (
    <div className="flex h-[180px] w-full items-end justify-start overflow-hidden px-4 pb-4 pt-8 ph:px-24 ph:pt-16 md:h-[200px] md:px-48 lg:px-72 xl:px-96">
      <h1 className="text-start text-3xl font-extrabold uppercase text-black lg:text-5xl">
        {title}
      </h1>
    </div>
  );
};
